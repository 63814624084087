import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import {
  SEASON_LIST_FETCH_SUCCESS,
  ResponseCode,
  NewTransferDispatchType,
  STYLE_NUMBER_LIST_FETCH_SUCCESS,
  STYLE_NUMBER_LIST_BY_SEASON_FETCH_SUCCESS,
  PRODUCT_COLORWAY_CODE_LIST_FETCH_SUCCESS,
  PRODUCT_PLANNING_GROUP_FETCH_SUCCESS,
  PRODUCT_PLANNING_GROUP_LIST_FETCH_SUCCESS,
  TO_FACTORY_LIST_FETCH_SUCCESS,
  ALL_TO_FACTORY_LIST_FETCH_SUCCESS,
  FROM_FACTORY_LIST_FETCH_SUCCESS,
  PCC_CODES_FETCH_SUCCESS,
  POST_TRANSFER_DATA_START,
  POST_TRANSFER_DATA_SUCCESS,
  POST_TRANSFER_DATA_END,
  POST_TRANSFER_DATA_ERROR,
  OUTSOLE_CODES_FETCH_SUCCESS,
  OUTSOLE_CODES_FETCH_LOADING,
  PCC_CODES_FETCH_LOADING,
  RESET_NEW_TRANSFER_DATA,
  PRODUCT_CODE_LIST_FETCH_SUCCESS,
  STYLE_NAME_FETCH_SUCCESS,
  PRODUCT_INFO_FETCH_SUCCESS,
  POST_TRANSFER_DATA_DASHBOARD_SUCCESS,
  PostTransferData,
  CLEAR_PRODUCT_INFO,
  ENGINEERING_TRANSFER_TYPE_FETCH_SUCCESS,
  TRANSFER_REASON_FETCH_SUCCESS,
  TRANSFER_TYPE_FETCH_SUCCESS,
} from '../types/newTransferTypes';
import {
  TRANSFER_STATUS_CONFIRMED,
  TRANSFER_STATUS_DRAFT,
  TRANSFER_STATUS_FACTORY,
  TRANSFER_STATUS_SOURCING,
} from '../../components/common/RolesConstants';
import apiConfig from '../../config/apiConfig';
import apiInstance from '../axios/index';

export const fetchProductInfo = async (fetch: string, params?: any) => {
  try {
    const getProductInfoUrl =
      apiConfig.baseURL +
      apiConfig.getProductInformation.url.replace('{type}', fetch);
    const response = await apiInstance.get(`${getProductInfoUrl}`, {
      params,
    });

    if (response && response.status === ResponseCode.OKAY) {
      return response.data;
    }

    return false;
  } catch (error) {
    return false;
  }
};

export const fetchSourcingProductInfo = async (fetch: string, params?: any) => {
  try {
    const getProductInfoUrl =
      apiConfig.baseURL +
      apiConfig.getSourcingProductInformation.url.replace('{type}', fetch);
    const response = await apiInstance.get(`${getProductInfoUrl}`, {
      params,
    });
    if (response && response.status === ResponseCode.OKAY) {
      return response.data;
    }

    return false;
  } catch (error) {
    return false;
  }
};

export const fetchProductId =
  (season: string, styleNumber: string, productColorwayCode: string) =>
  async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const response = await fetchProductInfo('productInfo', {
        seasonYearDescription: season,
        styleNumber,
        productColorwayCode,
      });

      if (response && response.data?.list) {
        dispatch({
          type: PRODUCT_INFO_FETCH_SUCCESS,
          payload: response.data.list[0],
        });
      }
    } catch (err) {
      toast.error('Something went wrong. Failed to get Product Info');
    }
  };

export const fetchSeasonList =
  () => async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const response = await fetchProductInfo('seasonYearDescription');

      if (response) {
        dispatch({
          type: SEASON_LIST_FETCH_SUCCESS,
          payload: response.data?.list || [],
        });
      }
    } catch (err) {
      toast.error('Something went wrong. Failed to get Season list');
    }
  };

export const fetchSourcingSeasonList =
  () => async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const response = await fetchSourcingProductInfo('seasonYearCode');

      if (response) {
        dispatch({
          type: SEASON_LIST_FETCH_SUCCESS,
          payload: response.data?.list || [],
        });
      }
    } catch (err) {
      toast.error('Something went wrong. Failed to get Season list');
    }
  };

export const fetchStyleNumberList =
  (styleNumber?: string) =>
  async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const params: any = {};
      if (styleNumber) {
        params.styleNumber = `${styleNumber.toUpperCase()}*`;
      }
      const response = await fetchProductInfo('styleNumber', params);
      if (response) {
        dispatch({
          type: STYLE_NUMBER_LIST_FETCH_SUCCESS,
          payload: response.data?.list || [],
        });
      }
    } catch (err) {
      toast.error(
        'Something went wrong. Failed to get product code and Style Number list'
      );
    }
  };

export const fetchStyleNumberListBySeason =
  (season: string) => async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const response = await fetchProductInfo('styleNumber', {
        seasonYearDescription: season,
      });
      if (response) {
        dispatch({
          type: STYLE_NUMBER_LIST_BY_SEASON_FETCH_SUCCESS,
          payload: response.data?.list || [],
        });
      }
    } catch (err) {
      toast.error('Something went wrong. Failed to get style number by season');
    }
  };

export const fetchSourcingStyleNumberListBySeason =
  (season: string, styleNumber?: string) =>
  async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const params: any = {
        seasonYearCode: season,
      };
      if (styleNumber) {
        params.styleNumber = `${styleNumber.toUpperCase()}*`;
      }
      const response = await fetchSourcingProductInfo('styleNumber', params);
      if (response) {
        dispatch({
          type: STYLE_NUMBER_LIST_FETCH_SUCCESS,
          payload: response.data?.list || [],
        });
      }
    } catch (err) {
      toast.error('Something went wrong. Failed to get style number by season');
    }
  };

export const fetchProductCodeList =
  (season: string, productCode?: string) =>
  async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const params: any = {
        seasonYearDescription: season,
      };
      if (productCode) {
        params.productCode = `${productCode.toUpperCase()}*`;
      }
      const response = await fetchProductInfo('productCode', params);
      if (response) {
        dispatch({
          type: PRODUCT_CODE_LIST_FETCH_SUCCESS,
          payload: response.data?.list || [],
        });
      }
    } catch (err) {
      toast.error('Something went wrong. Failed to get product code list');
    }
  };

export const fetchSourcingProductCodeList =
  (season: string, productCode?: string) =>
  async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const params: any = {
        seasonYearCode: season,
      };
      if (productCode) {
        params.productCode = `${productCode.toUpperCase()}*`;
      }
      const response = await fetchSourcingProductInfo('productCode', params);
      if (response) {
        dispatch({
          type: PRODUCT_CODE_LIST_FETCH_SUCCESS,
          payload: response.data?.list || [],
        });
      }
    } catch (err) {
      toast.error('Something went wrong. Failed to get product code list');
    }
  };

export const fetchProductColorwayCodeList =
  (season: string, styleNumber: string) =>
  async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const response = await fetchProductInfo('productColorwayCode', {
        seasonYearDescription: season,
        styleNumber,
      });

      if (response) {
        dispatch({
          type: PRODUCT_COLORWAY_CODE_LIST_FETCH_SUCCESS,
          payload: response.data?.list || [],
        });
      }
    } catch (err) {
      toast.error('Something went wrong. Failed to get Colorway Code list');
    }
  };

export const fetchSourcingProductColorwayCodeList =
  (season: string, styleNumber: string) =>
  async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const response = await fetchSourcingProductInfo('colorwayCode', {
        seasonYearCode: season,
        styleNumber,
      });

      if (response) {
        dispatch({
          type: PRODUCT_COLORWAY_CODE_LIST_FETCH_SUCCESS,
          payload: response.data?.list || [],
        });
      }
    } catch (err) {
      toast.error('Something went wrong. Failed to get Colorway Code list');
    }
  };

export const fetchStyleNameWithStyleNumber =
  (styleNumber: string) =>
  async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const response = await fetchProductInfo('styleName', {
        styleNumber,
      });

      if (response && response.data?.list && response.data.list[0]) {
        const { _source: source } = response.data.list[0];
        dispatch({
          type: STYLE_NAME_FETCH_SUCCESS,
          payload: source.styleName,
        });
      }
    } catch (err) {
      toast.error('Something went wrong. Failed to get Style Name');
    }
  };

export const fetchProductPlanningGroup =
  (styleNumber: string) =>
  async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const getPpgInformationUrl =
        apiConfig.baseURL + apiConfig.getPpgInformation.url;
      const response = await apiInstance.get(`${getPpgInformationUrl}`, {
        params: {
          styleNumber,
        },
      });

      if (
        response &&
        response.status === ResponseCode.OKAY &&
        response.data?.data?.PPGDesc
      ) {
        dispatch({
          type: PRODUCT_PLANNING_GROUP_FETCH_SUCCESS,
          payload: response.data.data.PPGDesc,
        });
      }
    } catch (err) {
      toast.error('Something went wrong. Failed to get PPG Information');
    }
  };

export const fetchProductPlanningGroupList =
  () => async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const getPpgInformationUrl = apiConfig.baseURL + apiConfig.getPpgList.url;
      const response = await apiInstance.get(`${getPpgInformationUrl}`, {});

      if (response && response.status === ResponseCode.OKAY) {
        dispatch({
          type: PRODUCT_PLANNING_GROUP_LIST_FETCH_SUCCESS,
          payload: response.data.data,
        });
      }
    } catch (err) {
      toast.error('Something went wrong. Failed to get PPG list');
    }
  };

export const fetchFactoryList = async (params?: any) => {
  try {
    const getFactoryUrl =
      apiConfig.baseURL + apiConfig.getSourcingAssignments.url;
    const response = await apiInstance.get(`${getFactoryUrl}`, { params });
    if (response && response.status === ResponseCode.OKAY) {
      return response.data;
    }

    return false;
  } catch (error) {
    return false;
  }
};

export const fetchToFactoryList =
  (styleNumber: string, seasonYearCode: string, colorwayCode?: string) =>
  async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const response = await fetchFactoryList({
        styleNumber,
        seasonYearCode,
        colorwayCode,
      });

      if (response) {
        dispatch({
          type: TO_FACTORY_LIST_FETCH_SUCCESS,
          payload: {
            toFactoryAssignments: response.toFactories || [],
          },
        });
      }
    } catch (err) {
      toast.error('Something went wrong. Failed to get To Factory list');
    }
  };

export const fetchFromFactoryList =
  (styleNumber: string, seasonYearCode: string, colorwayCode: string) =>
  async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const response = await fetchFactoryList({
        styleNumber,
        seasonYearCode,
        colorwayCode,
      });

      if (response) {
        dispatch({
          type: FROM_FACTORY_LIST_FETCH_SUCCESS,
          payload: {
            fromFactoryAssignments: response.fromFactories || [],
          },
        });
      }
    } catch (err) {
      toast.error('Something went wrong. Failed to get From Factory list');
    }
  };

export const fetchAllFactoryList =
  () => async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const getAllFactoryUrl =
        apiConfig.baseURL +
        apiConfig.getAllFactory.url.replace('{type}', 'toFactory');

      const response = await apiInstance.get(`${getAllFactoryUrl}`);

      if (response) {
        dispatch({
          type: ALL_TO_FACTORY_LIST_FETCH_SUCCESS,
          payload: {
            all: response.data.data || [],
          },
        });
      }
    } catch (err) {
      toast.error('Something went wrong. Failed to get To Factory list');
    }
  };

export const fetchPccCodes =
  (productOfferingCode: number) =>
  async (dispatch: Dispatch<NewTransferDispatchType>) => {
    dispatch({
      type: PCC_CODES_FETCH_LOADING,
    });
    try {
      const getPccCodesUrl =
        apiConfig.onDemandApiBaseURL + apiConfig.getPccCodes.url;
      const response = await apiInstance.get(`${getPccCodesUrl}`, {
        params: {
          productOfferingCode,
        },
      });
      if (response && response.data?.data) {
        dispatch({
          type: PCC_CODES_FETCH_SUCCESS,
          payload: response.data.data,
        });
      }
    } catch (err) {
      toast.error('Something went wrong. Failed to get PCC list');
    }
  };

export const fetchOutsoleCodes =
  (styleNumber: string) =>
  async (dispatch: Dispatch<NewTransferDispatchType>) => {
    dispatch({
      type: OUTSOLE_CODES_FETCH_LOADING,
    });
    try {
      const getOutsoleCodesUrl =
        apiConfig.onDemandApiBaseURL + apiConfig.getOutsoleCodes.url;
      const response = await apiInstance.get(`${getOutsoleCodesUrl}`, {
        params: {
          styleNumber,
        },
      });
      if (response && response.data?.data) {
        dispatch({
          type: OUTSOLE_CODES_FETCH_SUCCESS,
          payload: response.data.data,
        });
      }
    } catch (err) {
      toast.error('Something went wrong! Failed to get Outsole code');
    }
  };

export const postTransferDataCall =
  (data: PostTransferData) =>
  async (dispatch: Dispatch<NewTransferDispatchType>) => {
    dispatch({
      type: POST_TRANSFER_DATA_START,
    });
    try {
      const createTransferDataUrl =
        apiConfig.baseURL + apiConfig.createTransferData.url;
      const response: any = await apiInstance.post(
        `${createTransferDataUrl}`,
        data
      );

      if (
        response &&
        (response.status === ResponseCode.OKAY ||
          response.status === ResponseCode.CREATED)
      ) {
        dispatch({
          type: POST_TRANSFER_DATA_SUCCESS,
          payload: response.data.response[0].source,
        });

        for (let i = 0; i < response.data.response.length; i += 1) {
          dispatch({
            type: POST_TRANSFER_DATA_DASHBOARD_SUCCESS,
            payload: {
              ...response.data.response[i].source,
              _id: response.data.response[i].id,
            },
          });
        }

        let successMessage = '';
        switch (response.data.response[0].source.status) {
          case TRANSFER_STATUS_CONFIRMED:
            successMessage = 'Data Confirmed successfully';
            break;
          case TRANSFER_STATUS_DRAFT:
            successMessage = 'Data saved as Draft successfully';
            break;
          case TRANSFER_STATUS_SOURCING:
            successMessage = 'Data sent to Sourcing successfully';
            break;
          case TRANSFER_STATUS_FACTORY:
            successMessage = 'Data sent to Factory successfully';
            break;
          default:
            break;
        }
        toast.success(successMessage);
      }
    } catch (err: any) {
      const errorResponse = err?.response?.data?.response;
      const errors =
        Array.isArray(errorResponse) && errorResponse.length > 0
          ? errorResponse.map(
              (a: { colorwayCode: string; reason: string }) =>
                `For colorwayCode : ${a.colorwayCode} - ${a.reason}`
            )
          : errorResponse;
      dispatch({
        type: POST_TRANSFER_DATA_ERROR,
        payload: {
          message: errors,
          error: errorResponse,
        },
      });

      if (Array.isArray(err.response.data.response)) {
        toast.error(
          `Unable to save record(s) ${err.response.data.response[0]?.reason}`
        );
      } else {
        toast.error('Unable to save record(s)');
      }
    } finally {
      dispatch({
        type: POST_TRANSFER_DATA_END,
      });
    }
  };

export const resetNewTransferData = () => ({
  type: RESET_NEW_TRANSFER_DATA,
});

export const clearProductInfo = () => ({
  type: CLEAR_PRODUCT_INFO,
});

export const fetchEngineeringTransferType =
  () => async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const getEngineeringTransferTypeUrl =
        apiConfig.baseURL + apiConfig.getEngineeringTransferTypes.url;

      const response = await apiInstance.get(
        `${getEngineeringTransferTypeUrl}`,
        {}
      );

      if (response && response.status === ResponseCode.OKAY && response.data) {
        dispatch({
          type: ENGINEERING_TRANSFER_TYPE_FETCH_SUCCESS,
          payload: response.data.response,
        });
      }
    } catch (err) {
      toast.error(
        'Something went wrong. Failed to get Engineering Transfer Type Information'
      );
    }
  };

export const fetchTransferReason =
  () => async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const getTransferReasonUrl =
        apiConfig.baseURL + apiConfig.getTransferReasons.url;
      const response = await apiInstance.get(`${getTransferReasonUrl}`, {});

      if (response && response.status === ResponseCode.OKAY && response.data) {
        dispatch({
          type: TRANSFER_REASON_FETCH_SUCCESS,
          payload: response.data.response,
        });
      }
    } catch (err) {
      toast.error(
        'Something went wrong. Failed to get Transfer Reason Information'
      );
    }
  };

export const fetchTransferType =
  () => async (dispatch: Dispatch<NewTransferDispatchType>) => {
    try {
      const getTransferTypeUrl =
        apiConfig.baseURL + apiConfig.getTransferTypes.url;
      const response = await apiInstance.get(`${getTransferTypeUrl}`, {});

      if (response && response.status === ResponseCode.OKAY && response.data) {
        dispatch({
          type: TRANSFER_TYPE_FETCH_SUCCESS,
          payload: response.data.response,
        });
      }
    } catch (err) {
      toast.error(
        'Something went wrong. Failed to get Transfer Type Information'
      );
    }
  };

import axios from 'axios';
import moment from 'moment';
import { useAuthStore } from '@nike/aegis-auth-react';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import { fetchSttTokenHeaders } from '../../utils/sttToken';

const apiInstance = axios.create({
  paramsSerializer: params => stringify(params, { encode: false }),
});

apiInstance.interceptors.request.use(
  async config => {
    const storeData = useAuthStore.getState();
    const idClaims = { groups: [''], ...storeData.idClaims };

    const userid = idClaims?.email?.toLowerCase() || '';
    const userrole =
      (idClaims?.groups && idClaims?.groups[0]?.split('.').at(-1)) || '';
    const token = localStorage.getItem('access_token');

    if (token) {
      config.headers.set('Authorization', token);
      config.headers.set('Content-Type', 'application/json');
      config.headers.set('userid', userid);
      config.headers.set('userrole', userrole);
    }

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(
  async response => {
    try {
      let expireTimestampMinusTen;
      const currentTime = moment();
      const refresh = localStorage.getItem('refresh_token');
      if (refresh) {
        expireTimestampMinusTen = moment(parseInt(atob(refresh), 10)).subtract(
          10,
          'minutes'
        );

        if (moment(currentTime).isSameOrAfter(expireTimestampMinusTen)) {
          const accessTokenHeaders = await fetchSttTokenHeaders();

          localStorage.setItem(
            'access_token',
            accessTokenHeaders.Authorization
          );
          localStorage.setItem(
            'refresh_token',
            accessTokenHeaders.RefreshToken
          );
        }

        return response;
      }
    } catch (error) {
      toast.info('Your session has expired, please refresh the screen', {
        autoClose: false,
      });
      return Promise.reject(error);
    }
    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (!originalRequest.retry) {
      originalRequest.retry = true;

      try {
        const accessTokenHeaders = await fetchSttTokenHeaders();

        localStorage.setItem('access_token', accessTokenHeaders.Authorization);
        localStorage.setItem('refresh_token', accessTokenHeaders.RefreshToken);

        return await apiInstance(originalRequest);
      } catch (error) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default apiInstance;
